import * as React from "react";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import Box from "@mui/material/Box";

import { VariantSignificanceIcon } from "./VariantSignificanceIcon";
import getVariants from "../../apis/getVariants";
import VariantRow from "./ExpandableVariantRow";
import { romanToInt } from "../../utils/utils";
import preLodaer from "../../assets/images/preloader.gif";
import { useQuery } from "react-query";

const columns = [
  {
    field: "intervar_classification",
    headerName: "ACMG Classification",
    flex: 0.4,
    renderCell: (params) => {
      return <VariantSignificanceIcon classification={params.value} />;
    },
  },
  {
    field: "cancervar_classification",
    headerName: "AMP Classification",
    flex: 0.4,
    renderCell: (params) => {
      return <VariantSignificanceIcon classification={params.value} />;
    },

    // The tier numbering is in roman numerals, so we need to convert it to a number
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return (
        romanToInt(v1.split("#")[1].split("_")[0]) -
        romanToInt(v2.split("#")[1].split("_")[0])
      );
    },
  },
  { field: "gene_symbol", headerName: "Gene", flex: 0.2 },
  { field: "location", headerName: "Position", flex: 0.3 },
  { field: "function", headerName: "Function", flex: 0.2 },
  {
    field: "clinvar_classification",
    headerName: "Clinvar Significance",
    flex: 0.4,
  },
];

function VariantList(props) {
  const [page, setPage] = React.useState(0);

  const apiRef = useGridApiRef();
  // const [selectedRows, setSelectedRows] = React.useState([]);

  const { isLoading, isError, data, error } = useQuery(["variants", page], () =>
    getVariants(props.project_id, page + 1)
  );
  const [rowCountState, setRowCountState] = React.useState(data?.total || 0);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      data?.total !== undefined ? data?.total : prevRowCountState
    );
  }, [data?.total, setRowCountState]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "300px",
        }}
      >
        <img src={preLodaer} alt="preloader" />
      </Box>
    );
  }

  if (isError) {
    console.log(error);
  }

  return (
    <Box sx={{ height: "1000px", width: "100%" }}>
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box sx={{ flexGrow: 1 }}>
          <DataGrid
            rowCount={rowCountState}
            apiRef={apiRef}
            columns={columns}
            pagination
            paginationMode="server"
            rows={data.snvs}
            pageSize={25}
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
            components={{
              Row: VariantRow,
              Header: () => null,
            }}
            componentsProps={{
              row: {
                bam_files: props.bam_files,
                apiRef: apiRef,
              },
            }}
            disableSelectionOnClick
            sx={{
              border: 0,
              "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader": {
                display: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                marginTop: "0 !important",
              },
            }}
          />
          {/* <button onClick={handleButtonClick}>
            Create Report With Selected Variants
          </button> */}
        </Box>
      </Box>
    </Box>
  );
}
export default VariantList;
